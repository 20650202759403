import revive_payload_client_ideTFZ8ybI from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.6_eslint@9.11.1_jiti@1.21.6__ioredis@5.4._jgca2ac3kgjcg6klngy453euru/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_eexe52Y7Ww from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.6_eslint@9.11.1_jiti@1.21.6__ioredis@5.4._jgca2ac3kgjcg6klngy453euru/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_NaHVuyG1Gz from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.6_eslint@9.11.1_jiti@1.21.6__ioredis@5.4._jgca2ac3kgjcg6klngy453euru/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_9rnOP1TnLM from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.6_eslint@9.11.1_jiti@1.21.6__ioredis@5.4._jgca2ac3kgjcg6klngy453euru/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_8oeGN4obcX from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.6_eslint@9.11.1_jiti@1.21.6__ioredis@5.4._jgca2ac3kgjcg6klngy453euru/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_iRXCnKn6za from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.6_eslint@9.11.1_jiti@1.21.6__ioredis@5.4._jgca2ac3kgjcg6klngy453euru/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_acv24EaNbP from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.6_eslint@9.11.1_jiti@1.21.6__ioredis@5.4._jgca2ac3kgjcg6klngy453euru/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_PONYjBVMuZ from "/opt/render/project/src/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.22.4_typescript@5.6.2_vue@3.5.8_typescript@5.6.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/.nuxt/components.plugin.mjs";
import prefetch_client_eKF5vvB15U from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.6_eslint@9.11.1_jiti@1.21.6__ioredis@5.4._jgca2ac3kgjcg6klngy453euru/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_WiTrEocjY4 from "/opt/render/project/src/node_modules/.pnpm/@nuxt+icon@1.5.3_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@20.16.6_sass@1.79.3_ters_zkjj4r4qxdtg3sx5ekbhfh4aey/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import primevue_plugin_egKpok8Auk from "/opt/render/project/src/.nuxt/primevue-plugin.mjs";
import plugin_client_v5hZUDzFTx from "/opt/render/project/src/node_modules/.pnpm/@primevue+nuxt-module@4.0.7_@babel+parser@7.25.6_magicast@0.3.5_rollup@4.22.4_vue@3.5.8_typescript@5.6.2_/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import plugin_wy0B721ODc from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_ph1uCBrURY from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.11_magicast@0.3.5_rollup@4.22.4_typescript@5.6.2_vue@3.5.8_typescript@5.6.2_/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import plugin_OYPQe8uMfO from "/opt/render/project/src/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.22.4_typesc_jk3hu7acfsykhbdo6gg6wmvw7u/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import apollo_1ILFkzpYSr from "/opt/render/project/src/plugins/apollo.ts";
import auto_animate_uTpJuTGsMC from "/opt/render/project/src/plugins/auto-animate.ts";
import error_handler_kEP5FliEXj from "/opt/render/project/src/plugins/error-handler.ts";
import maska_UHaKf2z1iQ from "/opt/render/project/src/plugins/maska.ts";
import notifications_Wskzy5ZO71 from "/opt/render/project/src/plugins/notifications.ts";
export default [
  revive_payload_client_ideTFZ8ybI,
  unhead_eexe52Y7Ww,
  router_NaHVuyG1Gz,
  payload_client_9rnOP1TnLM,
  navigation_repaint_client_8oeGN4obcX,
  check_outdated_build_client_iRXCnKn6za,
  chunk_reload_client_acv24EaNbP,
  plugin_vue3_PONYjBVMuZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_eKF5vvB15U,
  plugin_WiTrEocjY4,
  primevue_plugin_egKpok8Auk,
  plugin_client_v5hZUDzFTx,
  plugin_wy0B721ODc,
  plugin_ph1uCBrURY,
  plugin_OYPQe8uMfO,
  apollo_1ILFkzpYSr,
  auto_animate_uTpJuTGsMC,
  error_handler_kEP5FliEXj,
  maska_UHaKf2z1iQ,
  notifications_Wskzy5ZO71
]