import validate from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.6_eslint@9.11.1_jiti@1.21.6__ioredis@5.4._jgca2ac3kgjcg6klngy453euru/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.6_eslint@9.11.1_jiti@1.21.6__ioredis@5.4._jgca2ac3kgjcg6klngy453euru/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/opt/render/project/src/middleware/auth.ts"),
  form: () => import("/opt/render/project/src/middleware/form.ts"),
  submission: () => import("/opt/render/project/src/middleware/submission.ts")
}